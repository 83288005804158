const initialState = {
    headerData: undefined,
    monthlyChart: undefined,
    weeklyChart: undefined,
    monthlyBreak: undefined
}

const adminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HEADER_DATA":
            return {
                ...state,
                headerData: action.headers,
                monthlyChart: action.monthlyChart,
                weeklyChart: action.weeklyChart,
                monthlyBreak: action.monthlyBreak
            }
        default:
            return state
    }
}

export default adminDashboardReducer