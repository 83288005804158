const initialState = {
    loading: false,
    modal: -1,
    staffs: [],
    logs: [],
    total: 0,
    delStatus: undefined
}

const staffParkingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_STAFF":
            return {
                ...state,
                loading: true,
                modal: 1
            }
        case "ADD_STAFF_SUCCESS":
            state.staffs.unshift(action.staff)
            return { ...state, loading: false, modal: 0 }
        case "ADD_STAFF_FAILED":
            return {
                ...state,
                loading: false,
                modal: 1
            }
        case "GET_STAFFS":
            return {
                ...state,
                staffs: action.staffs,
                total: action.total,
                logs: action.staff_logs
            }
        case "UPDATE_STAFF_SUCCESS":
            let newState = {...state}
            newState.staffs.splice(action.index, 1, action.staff);
            return {
                ...state,
                loading: false,
                modal: 0
            }
        case "DELETE_STAFF":
            return {
                ...state,
                delStatus: -1
            }
        case "DELETE_STAFF_SUCCESS":
            let originState = {...state}
            originState.staffs.splice(action.index, 1);
            return {
                ...state,
                delStatus: 1
            }
        case "DELETE_STAFF_FAILED":
            return {
                ...state,
                delStatus: 0
            }
        default:
            return state
    }
}

export default staffParkingReducer