const initialState = {
    loading: false,
    modal: -1,
    editModal: -1,
    locations: [],
    total: 0,
    delStatus: undefined
}

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_LOCATION":
            return {
                ...state,
                loading: true,
                modal: 1
            }
        case "INITIAL":
            return {
                ...state,
                delStatus: undefined
            }
        case "ADD_LOCATION_SUCCESS":
            state.locations.unshift(action.location)
            return { ...state, loading: false, modal: 0 }
        case "ADD_LOCATION_FAILED":
            return {
                ...state,
                loading: false,
                modal: 1
            }
        case "GET_LOCATIONS":
            return {
                ...state,
                locations: action.locations,
                total: action.total
            }
        case "UPDATE_LOCATION_SUCCESS":
            let newState = {...state}
            newState.locations.splice(action.index, 1, action.location);
            return {
                ...state,
                loading: false,
                modal: 0
            }
        case "DELETE_LOCATION":
            return {
                ...state,
                delStatus: -1
            }
        case "DELETE_LOCATION_SUCCESS":
            let originState = {...state}
            originState.locations.splice(action.index, 1);
            return {
                ...state,
                delStatus: 1
            }
        case "DELETE_LOCATION_FAILED":
            return {
                ...state,
                delStatus: 0
            }
        default:
            return state
    }
}

export default locationReducer