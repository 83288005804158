import * as firebase from "firebase/app"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"
import axios from "axios"
import { config } from "../../../authServices/firebase/firebaseConfig"
import { toast } from "react-toastify"
import {commonPost, deviseLogout} from "../../apiHelper";

toast.configure();

// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let firebaseAuth = firebase.auth()

// const initAuth0 = new auth0.WebAuth(configAuth)


export const loginWithJWT = user => {
  return dispatch => {
    axios
      .post('/api/v1/auth/sign_in', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        let loggedInUser

        if (response.data) {
          loggedInUser = response.data.data;
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
          localStorage.setItem("authHeaders", JSON.stringify(response.headers));
          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser }
          })
          if(loggedInUser.role === 'admin') {
            history.push("/")
          } else {
            history.push('super_admin/dashboard')
          }

        }
      })
      .catch(err => {
        toast.error(err.response.data.errors[0]);
      })
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    deviseLogout('/api/v1/auth/sign_out').then((res) => {
      if(res.success) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("authHeaders");
        history.push("/login");
      } else {
        window.history.go(-1)
      }
    })
    .catch(err => {
      toast.error(err.response.data.errors[0])
    })
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    // history.push("/login")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
