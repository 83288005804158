const initialState = {
    loading: false,
    modal: -1,
    editModal: -1,
    hotels: [],
    total: 0,
    delStatus: -1,
    liveHotels: undefined
}

const hotelReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_HOTEL":
            return {
                ...state,
                loading: true,
                modal: 1
            }
        case "ADD_HOTEL_SUCCESS":
            state.hotels.unshift(action.hotel)
            return { ...state, loading: false, modal: 0 }
        case "ADD_HOTEL_FAILED":
            return {
                ...state,
                loading: false,
                modal: 1
            }
        case "GET_HOTELS":
            return {
                ...state,
                hotels: action.hotels,
                total: action.total
            }
        case "UPDATE_HOTEL_SUCCESS":
            let newState = {...state}
            newState.hotels.splice(action.index, 1, action.hotel);
            return {
                ...state,
                loading: false,
                modal: 0
            }
        case "DELETE_HOTEL":
            return {
                ...state,
                delStatus: -1
            }
        case "DELETE_HOTEL_SUCCESS":
            let originState = {...state}
            originState.hotels.splice(action.index, 1, action.hotel);
            return {
                ...state,
                delStatus: 1
            }
        case "DELETE_HOTEL_FAILED":
            return {
                ...state,
                delStatus: 0
            }
        case "LIVE_HOTELS":
            return {
                ...state,
                liveHotels: action.hotels
            }
        default:
            return state
    }
}

export default hotelReducer