const initialState = {
    loading: false,
    modal: -1,
    editModal: -1,
    total: 0,
    delStatus: -1,

    admins: []
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_ADMIN":
            return {
                ...state,
                loading: true,
                delStatus: -1,
                modal: 1
            }
        case "ADD_ADMIN_SUCCESS":
            state.admins.unshift(action.hotel)
            return { ...state, loading: false, modal: 0 }
        case "ADD_ADMIN_FAILED":
            return {
                ...state,
                loading: false,
                modal: 1,
                delStatus: -1
            }
        case "GET_ADMINS":
            return {
                ...state,
                admins: action.admins,
                total: action.total
            }
        case "UPDATE_ADMIN_SUCCESS":
            let newState = {...state}
            newState.admins.splice(action.index, 1, action.admin);
            return {
                ...state,
                loading: false,
                delStatus: -1,
                modal: 0
            }
        case "UPDATE_ADMIN_WITH_IMAGE":
            let newAdminState = {...state}
            newAdminState.admins.splice(action.index, 1, action.admin);
            return {
                ...state
            }
        case "DELETE_ADMIN":
            return {
                ...state,
                delStatus: -1
            }
        case "DELETE_ADMIN_SUCCESS":
            let originState = {...state}
            originState.admins.splice(action.index, 1);
            return {
                ...state,
                delStatus: 1
            }
        case "DELETE_ADMIN_FAILED":
            return {
                ...state,
                delStatus: 0
            }
        default:
            return state
    }
}

export default adminReducer