import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"

//Real project
import hotelReducer from "./hotel"
import adminUserReducer from "./admin_users"
import GateReducer from "./gate"
import RateReducer from "./parking_rate"
import SettingsReducer from "./settings"
import StaffParkingReducer from "./staff_parking"
import SelfParkingReducer from "./self_parking"
import ReportsReducer from "./reports"
import ValetManagingReducer from "./manage_valets"
import LocationReducer from "./parking_locations"
import AdminDashboardReducer from "./dashboard";
import SuperDashboardReducer from "./super_dashboard";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  hotel: hotelReducer,
  adminUser: adminUserReducer,
  gate: GateReducer,
  rate: RateReducer,
  settings: SettingsReducer,
  staff: StaffParkingReducer,
  selfParking: SelfParkingReducer,
  reports: ReportsReducer,
  valets: ValetManagingReducer,
  locations: LocationReducer,
  adminDashboard: AdminDashboardReducer,
  superDashboard: SuperDashboardReducer
})

export default rootReducer
