const initialState = {
    loading: false,
    modal: -1,
    editModal: -1,
    gates: [],
    total: 0,
    delStatus: undefined
}

const gateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_GATE":
            return {
                ...state,
                loading: true,
                modal: 1
            }
        case "INITIAL":
            return {
                ...state,
                delStatus: undefined
            }
        case "ADD_GATE_SUCCESS":
            state.gates.unshift(action.gate)
            return { ...state, loading: false, modal: 0 }
        case "ADD_GATE_FAILED":
            return {
                ...state,
                loading: false,
                modal: 1
            }
        case "GET_GATES":
            return {
                ...state,
                gates: action.gates,
                total: action.total
            }
        case "UPDATE_GATE_SUCCESS":
            let newState = {...state}
            newState.gates.splice(action.index, 1, action.gate);
            return {
                ...state,
                loading: false,
                modal: 0
            }
        case "DELETE_GATE":
            return {
                ...state,
                delStatus: -1
            }
        case "DELETE_GATE_SUCCESS":
            let originState = {...state}
            originState.gates.splice(action.index, 1);
            return {
                ...state,
                delStatus: 1
            }
        case "DELETE_GATE_FAILED":
            return {
                ...state,
                delStatus: 0
            }
        default:
            return state
    }
}

export default gateReducer