import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin"],
    navLink: "/"
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: "Manage Parking"
  // },
  {
    id: "self_parking",
    title: "Self Parking",
    type: "item",
    icon:  <i className="fas fa-car" aria-hidden="true" style={{fontSize: "20px", marginRight: '14px'}}></i>,
    permissions: ["admin"],
    navLink: "/admin/self_parking"
  },
  {
    id: "staff_parking",
    title: "Staff Parking",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/admin/staff_parking"
  },
  {
    id: "manage_valets",
    title: "Valet Parking",
    type: "item",
    icon: <i className="fas fa-users-cog" aria-hidden="true" style={{fontSize: "20px", marginRight: '14px'}}></i>,
    permissions: ["admin"],
    navLink: "/admin/manage_valets"
  },
  {
    id: "gates",
    title: "Gates",
    type: "item",
    icon: <Icon.Columns size={20} />,
    permissions: ["admin"],
    navLink: "/admin/gates"
  },
  {
    id: "parking_rates",
    title: "Parking Rates",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin"],
    navLink: "/admin/parking_rates"
  },
  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <i className="fas fa-download" aria-hidden="true" style={{fontSize: "20px", marginRight: '14px'}}></i>,
    permissions: ["admin"],
    navLink: "/admin/reports"
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin"],
    navLink: "/admin/settings"
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: "Super Admin"
  // },
  {
    id: "superAdminDashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["super_admin"],
    navLink: "/super_admin/dashboard"
  },
  {
    id: "hotelList",
    title: "All Hotels",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: ["super_admin"],
    navLink: "/super_admin/hotel_list"
  },
  {
    id: "adminUsers",
    title: "Admin Users",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    permissions: ["super_admin"],
    navLink: "/super_admin/admin_users"
  }
]

export default navigationConfig
