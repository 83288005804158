import axios from 'axios';


const commonPost = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'post',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const uploadImage = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
            "Content-Type": "multipart/form-data"
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const registerUser = (url, data) => {
    const config = {
        method: 'post',
        url: url,
        data : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const commonGet = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const commonBlobGet = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        responseType: 'blob'
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const commonGetWithUrl = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const commonPut = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "put",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const commonDelete = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "delete",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response;
        return response;
    }).catch(err => {
        return err.response
    });
}

const deviseLogout = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'delete',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response;
        return response.data;
    }).catch(err => {
        return err.response;
    });
}

export {commonGet, commonBlobGet, commonGetWithUrl, commonPost, uploadImage, commonPut, commonDelete, deviseLogout, registerUser}