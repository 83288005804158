const initialState = {
    loading: false,
    guestTotal: 0,
    staffTotal: 0,
    valetTotal: 0,
    guestReports: [],
    staffReports: [],
    valetReports: []
}

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "INIT":
            return {
                ...state,
                loading: true
            }
        case "GET_GUEST_LOGS":
            return {
                ...state,
                guestReports: action.guestReports,
                guestTotal: action.total
            }
        case "GET_STAFF_LOGS":
            return {
                ...state,
                staffReports: action.staffReports,
                staffTotal: action.total
            }
        case "GET_VALET_LOGS":
            return {
                ...state,
                valetReports: action.valetReports,
                valetTotal: action.total
            }
        case "CSV_EXPORT_SUCCESS":
            return {
                ...state,
                loading: false
            }
        case "REFUND_SUCCESS":
            let newState = {...state}
            newState.valetReports[action.index] = action.parkingLog
            return {
                ...state,
                valetReports: [action.parkingLog]
            }
        case "REMOVE_ONE_ROW":
            let originState = {...state}
            originState.valetReports.splice(action.index, 1);
            return {
                ...state
            }
        default:
            return state
    }
}

export default reportsReducer
