import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Auth0Provider } from "../src/authServices/auth0/auth0Service"
import config from "../src/authServices/auth0/auth0Config.json"
// import { IntlProviderWrapper } from "../src/utility/context/Internationalization"
import { Layout } from "../src/utility/context/Layout"
import * as serviceWorker from "../src/serviceWorker"
import { store } from "../src/redux/storeConfig/store"
import Spinner from "../src/components/@vuexy/spinner/Fallback-spinner"
import "../src/index.scss"
import "../src/@fake-db"

const LazyApp = lazy(() => import("../src/App"))

// configureDatabase()
document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}>
            <Provider store={store}>
                <Suspense fallback={<Spinner />}>
                    <Layout>
                        {/*<IntlProviderWrapper>*/}
                            <LazyApp />
                        {/*</IntlProviderWrapper>*/}
                    </Layout>
                </Suspense>
            </Provider>
        </Auth0Provider>,
        document.body.appendChild(document.createElement('div')),
    )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
