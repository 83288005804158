const initialState = {
    loading: false,
    hotelInfo: undefined
}

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_MY_HOTEL":
            return {
                ...state,
                hotelInfo: action.hotel
            }
        case "UPDATE_SETTINGS":
            return {
                ...state,
                loading: true
            }
        case "UPDATE_SETTINGS_SUCCESS":
            return {
                ...state,
                hotelInfo: action.hotel,
                loading: false
            }
        case "UPDATE_SETTINGS_FAILED":
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default settingsReducer