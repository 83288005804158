const initialState = {
    headerData: undefined,
    hotelList: undefined
}

const superAdminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SUPER_HEADER_DATA":
            return {
                ...state,
                headerData: action.headers,
                hotelList: action.hotelList
            }
        default:
            return state
    }
}

export default superAdminDashboardReducer